<template>
  <ais-instant-search :search-client="searchClient" :index-name="indexName">
    <b-row>
      <b-col xl="8">
        <div class="tab-content p-0">
          <!-- BEGIN tab-pane -->
          <div class="tab-pane fade show active">
            <ais-search-box />

            <ais-configure :filters="`user_id:${user.id}`" />

            <ais-infinite-hits>
              <b-list-group slot-scope="{ items }" class="mt-4">
                <template v-if="items.length">
                  <b-list-group-item v-for="order in items" :key="order.objectID">
                    <div class="text-muted">
                      <small>
                        <strong>Order #{{ order.id }}</strong>
                      </small>
                      bought from
                      <strong>{{ order.company_name }}</strong> on
                      {{ order.created_at_formatted }}
                    </div>
                  </b-list-group-item>
                </template>

                <b-list-group-item v-else>
                  <b-row align-h="center">
                    <b-col cols="auto" class="py-5">
                      <strong>No orders yet</strong>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </ais-infinite-hits>
          </div>
          <!-- END tab-pane -->
        </div>
      </b-col>
      <b-col xl="4">
        <div class="desktop-sticky-top d-none d-lg-block">
          <b-card no-body class="mb-3">
            <b-list-group flush>
              <b-list-group-item class="font-weight-600 pl-3 pr-3 d-flex">
                <span class="flex-fill">Filters</span>
              </b-list-group-item>
              <b-list-group-item class="pl-3 pr-3">
                <div class="text-muted">
                  <small><strong>Companies</strong></small>
                </div>
                <ais-refinement-list attribute="company_name" searchable />
              </b-list-group-item>
              <b-list-group-item class="pl-3 pr-3">
                <div class="text-muted">
                  <small><strong>Payment Method</strong></small>
                </div>
                <ais-refinement-list attribute="payment_method" />
              </b-list-group-item>
              <b-list-group-item class="pl-3 pr-3">
                <div class="text-muted">
                  <small><strong>Delivery Method</strong></small>
                </div>
                <ais-refinement-list attribute="delivery_method" />
              </b-list-group-item>
              <b-list-group-item class="pl-3 pr-3">
                <div class="text-muted">
                  <small><strong>Paid</strong></small>
                </div>
                <ais-refinement-list attribute="paid" />
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </ais-instant-search>
</template>

<script>
import { users } from '@/api'
import { configureSearchClient, formatDate } from '../page-helpers'
import 'instantsearch.css/themes/algolia-min.css'
import { formatDateOrdered } from '@/pages/page-helpers'


export default {
  name: 'UserProfile',
  async mounted() {
    const user = await users.find(this.$route.params.id)

    this.user = user.data
  },
  data() {
    return {
      user: {},
      indexName: process.env.VUE_APP_ALGOLIA_ORDERS_INDEX,
      searchClient: configureSearchClient(
        process.env.VUE_APP_ALGOLIA_ID,
        process.env.VUE_APP_ALGOLIA_SEARCH_KEY,
        process.env.VUE_APP_ALGOLIA_LOCAL_READ_API
      ),
    }
  },
  methods: { formatDateOrdered },
}
</script>
